import { createApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';

import {
    CandidateInvitation,
    Invitation,
    RecruiterInvitation,
    RecruiterRemoval,
    Recruitment,
    RecruitmentDTO,
    SessionDeletePayload,
    SessionDTO,
    SessionParticipant,
    SessionParticipants,
    SessionPayload,
    SessionUpdatePayload
} from 'models/recruitment';
import { CreateWorkspacePayload, Workspace, WorkspaceAction, WorkspaceId } from 'models/workspace';
import axiosBaseQuery from 'store/axiosBaseQuery';
import { CreateSnapshotPayload, SnapshotDTO } from 'models/snapshot';

export const recruitmentService = {
    recruiterUploadAvatarUrl: '/recruiters/alias/me/avatar/stream',
    getRecruitmentAvatarUrl: (recruitmentId: string) => {
        return `/recruitments/one/${recruitmentId}/avatar/stream`;
    },
    getRecruiterAvatarUrl: (recruiterId: string, me?: boolean) => {
        return `/tenants/one/cmit/recruiters/${me ? 'alias/me' : `one/${recruiterId}`}/avatar/stream`;
    },
    getCandidateFileUrl: (candidateId: string) => {
        return `/candidates/one/${candidateId}/file/stream`;
    },
    isUserOwnerOrRecruiter: (recruitments: RecruitmentDTO[], userId: string) => {
        return recruitments.some(
            (r) => ![r.owner.id, ..._.flatMap(_.flatMap(r.sessions, 'recruiters'), 'id')].includes(userId)
        );
    }
};

enum TagType {
    Recruitment = 'recruitment',
    Session = 'session',
    Participant = 'participant',
    Snapshots = 'snapshots',
    Files = 'files'
}

enum TagId {
    List = 'list'
}

export const recruitmentApiService = createApi({
    reducerPath: 'recruitmentApiService',
    baseQuery: axiosBaseQuery({ basePath: '/recruitments' }),
    tagTypes: [TagType.Recruitment, TagType.Session, TagType.Participant, TagType.Snapshots, TagType.Files],
    endpoints: (builder) => ({
        getMyRecruitments: builder.query<RecruitmentDTO[], void>({
            query: () => ({
                url: '/alias/my',
                method: 'get'
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: TagType.Recruitment, id } as const)),
                          { type: TagType.Recruitment, id: TagId.List }
                      ]
                    : [{ type: TagType.Recruitment, id: TagId.List }]
        }),
        getRecruitment: builder.query<RecruitmentDTO, string>({
            query: (recruitmentId) => ({ url: `/one/${recruitmentId}`, method: 'get' }),
            providesTags: (result, error, id) => [{ type: TagType.Recruitment, id }]
        }),
        addRecruitment: builder.mutation<RecruitmentDTO, Partial<Recruitment>>({
            query: (data) => ({
                url: '',
                method: 'post',
                data
            }),
            invalidatesTags: [{ type: TagType.Recruitment, id: TagId.List }]
        }),
        updateRecruitment: builder.mutation<RecruitmentDTO, Partial<Recruitment>>({
            query: (data) => ({
                url: `/one/${data.id}`,
                method: 'put',
                data
            }),
            invalidatesTags: (result, error, { id }) => [{ type: TagType.Recruitment, id }]
        }),
        deleteRecruitment: builder.mutation<RecruitmentDTO, string>({
            query: (recruitmentId) => {
                return { url: `/one/${recruitmentId}`, method: 'delete' };
            },
            invalidatesTags: (result, error, id) => [{ type: TagType.Recruitment, id }]
        }),
        getRecruiterSession: builder.query<SessionDTO, Invitation>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}`,
                method: 'get'
            }),
            onQueryStarted({ recruitmentId, sessionId }, { dispatch, queryFulfilled }) {
                queryFulfilled.then(({ data }) => {
                    dispatch(refreshSession(recruitmentId, data));
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getMyRecruitments',
                            undefined,
                            (recruitments: RecruitmentDTO[]) => {
                                const rIdx = _.findIndex(recruitments, ['id', recruitmentId]);
                                if (rIdx === -1) {
                                    return;
                                }
                                const sIdx = _.findIndex(recruitments[rIdx].sessions, ['id', sessionId]);
                                if (sIdx === -1) {
                                    return;
                                }
                                Object.assign(recruitments[rIdx].sessions[sIdx], { workspace: data.workspace });
                            }
                        )
                    );
                });
            },
            providesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        getCandidateSession: builder.query<SessionDTO, WorkspaceId>({
            query: ({ recruitmentId, sessionId, code }) => ({
                basePathOverride: '/tenants',
                url: `/one/cmit/recruitments/one/${recruitmentId}/sessions/one/${sessionId}`,
                method: 'get',
                params: { code }
            }),
            providesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        addSession: builder.mutation<SessionDTO, SessionPayload>({
            query: ({ recruitmentId, ...data }) => ({
                url: `/one/${recruitmentId}/sessions`,
                method: 'post',
                data
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        updateSession: builder.mutation<SessionDTO, SessionUpdatePayload>({
            query: (data) => ({
                url: `/one/${data.recruitmentId}/sessions/one/${data.id}`,
                method: 'put',
                data
            }),
            invalidatesTags: (result, error, { recruitmentId, id }) => [
                { type: TagType.Recruitment, id: recruitmentId },
                { type: TagType.Session, id: id }
            ]
        }),
        deleteSession: builder.mutation<SessionDTO, SessionDeletePayload>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}`,
                method: 'delete'
            }),
            invalidatesTags: (result, error, { recruitmentId, sessionId }) => [
                { type: TagType.Recruitment, id: recruitmentId },
                { type: TagType.Session, id: sessionId }
            ]
        }),
        uploadCV: builder.mutation<void, { url: string; cv: File; recruitmentId: string; sessionId: string }>({
            query: ({ url, cv, recruitmentId, sessionId }) => ({
                basePathOverride: '',
                url,
                params: {
                    path: cv.name
                },
                method: 'put',
                headers: { 'Content-Type': cv.type },
                data: cv
            }),
            invalidatesTags: (result, error, { url, recruitmentId, sessionId }) => [
                { type: TagType.Files, id: url },
                { type: TagType.Recruitment, id: recruitmentId },
                { type: TagType.Session, id: sessionId }
            ]
        }),
        inviteCandidate: builder.mutation<RecruitmentDTO, CandidateInvitation>({
            query: ({ recruitmentId, sessionId, candidate }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/invite/participant`,
                data: candidate,
                method: 'post'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        inviteMember: builder.mutation<RecruitmentDTO, RecruiterInvitation>({
            query: ({ recruitmentId, sessionId, recruiter }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/invite/member`,
                data: recruiter,
                method: 'post'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        inviteRecruiter: builder.mutation<RecruitmentDTO, RecruiterInvitation>({
            query: ({ recruitmentId, sessionId, recruiter }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/invite/recruiter`,
                data: recruiter,
                method: 'post'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        removeRecruiter: builder.mutation<RecruitmentDTO, RecruiterRemoval>({
            query: ({ recruitmentId, sessionId, recruiterId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/recruiters/one/${recruiterId}`,
                method: 'delete'
            }),
            invalidatesTags: (result, error, { recruitmentId }) => [{ type: TagType.Recruitment, id: recruitmentId }]
        }),
        getRecruiterSessionParticipants: builder.query<SessionParticipant[], WorkspaceId>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/participants`,
                method: 'get'
            }),
            transformResponse: (response: SessionParticipants) => [
                { ...response.candidate, isCandidate: true },
                ..._.sortBy(response.recruiters, ['first_name', 'last_name'])
            ],
            providesTags: () => [{ type: TagType.Participant, id: TagId.List }]
        }),
        getCandidateSessionParticipants: builder.query<SessionParticipant[], WorkspaceId>({
            query: ({ recruitmentId, sessionId, code }) => ({
                basePathOverride: '/tenants',
                url: `/one/cmit/recruitments/one/${recruitmentId}/sessions/one/${sessionId}/participants`,
                method: 'get',
                params: { code }
            }),
            transformResponse: (response: SessionParticipants) => [
                { ...response.candidate, isCandidate: true },
                ..._.sortBy(response.recruiters, ['first_name', 'last_name'])
            ],
            providesTags: () => [{ type: TagType.Participant, id: TagId.List }]
        }),
        createWorkspace: builder.mutation<
            Workspace,
            { recruitmentId: string; sessionId: string; payload: CreateWorkspacePayload }
        >({
            query: ({ recruitmentId, sessionId, payload }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/workspace`,
                method: 'post',
                data: payload
            }),
            async onQueryStarted({ recruitmentId, sessionId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: workspace } = await queryFulfilled;

                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getRecruitment',
                            recruitmentId,
                            (recruitment: RecruitmentDTO) => {
                                const sIdx = _.findIndex(recruitment.sessions, ['id', sessionId]);
                                Object.assign(recruitment.sessions[sIdx], { workspace: workspace });
                            }
                        )
                    );
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getMyRecruitments',
                            undefined,
                            (recruitments: RecruitmentDTO[]) => {
                                const rIdx = _.findIndex(recruitments, ['id', recruitmentId]);
                                const sIdx = _.findIndex(recruitments[rIdx].sessions, ['id', sessionId]);
                                Object.assign(recruitments[rIdx].sessions[sIdx], { workspace: workspace });
                            }
                        )
                    );
                } catch {}
            },
            invalidatesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        deleteWorkspace: builder.mutation<Workspace, { recruitmentId: string; sessionId: string }>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/workspace`,
                method: 'delete',
                data: null
            }),
            async onQueryStarted({ recruitmentId, sessionId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getRecruitment',
                            recruitmentId,
                            (recruitment: RecruitmentDTO) => {
                                const sIdx = _.findIndex(recruitment.sessions, ['id', sessionId]);
                                Object.assign(recruitment.sessions[sIdx], { workspace: null });
                            }
                        )
                    );
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getMyRecruitments',
                            undefined,
                            (recruitments: RecruitmentDTO[]) => {
                                const rIdx = _.findIndex(recruitments, ['id', recruitmentId]);
                                const sIdx = _.findIndex(recruitments[rIdx].sessions, ['id', sessionId]);
                                Object.assign(recruitments[rIdx].sessions[sIdx], { workspace: null });
                            }
                        )
                    );
                } catch {}
            },
            invalidatesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        changeWorkspaceStatus: builder.mutation<
            Workspace,
            { recruitmentId: string; sessionId: string; action: WorkspaceAction }
        >({
            query: ({ recruitmentId, sessionId, action }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/workspace/${action}`,
                method: 'put',
                data: null
            }),
            async onQueryStarted({ recruitmentId, sessionId, ...patch }, { dispatch, queryFulfilled }) {
                try {
                    const { data: workspace } = await queryFulfilled;
                    dispatch(
                        recruitmentApiService.util.updateQueryData(
                            'getRecruitment',
                            recruitmentId,
                            (recruitment: RecruitmentDTO) => {
                                const sIdx = _.findIndex(recruitment.sessions, ['id', sessionId]);
                                Object.assign(recruitment.sessions[sIdx], { workspace: workspace });
                            }
                        )
                    );
                } catch {}
            },
            invalidatesTags: (result, error, { sessionId }) => [{ type: TagType.Session, id: sessionId }]
        }),
        createSnapshot: builder.mutation<string, CreateSnapshotPayload>({
            query: ({ workspaceId: { recruitmentId, sessionId }, data }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/workspace/snapshot`,
                method: 'post',
                data
            }),
            invalidatesTags: () => [{ type: TagType.Snapshots, id: TagId.List }]
        }),
        deleteSnapshot: builder.mutation<SnapshotDTO, string>({
            query: (snapshotId) => ({
                basePathOverride: '/snapshots',
                url: `/one/${snapshotId}`,
                method: 'delete'
            }),
            invalidatesTags: () => [{ type: TagType.Snapshots, id: TagId.List }]
        }),
        getRecruitmentSnapshots: builder.query<SnapshotDTO[], string>({
            query: (recruitmentId) => ({
                url: `/one/${recruitmentId}/snapshots`,
                method: 'get'
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: TagType.Snapshots, id } as const)),
                          { type: TagType.Snapshots, id: TagId.List }
                      ]
                    : [{ type: TagType.Snapshots, id: TagId.List }]
        })
    })
});

export const refreshSession = (recruitmentId: string, session: SessionDTO): any => {
    return recruitmentApiService.util.updateQueryData('getRecruitment', recruitmentId, (recruitment: RecruitmentDTO) => {
        const sIdx = _.findIndex(recruitment?.sessions, ['id', session.id]);
        if (sIdx > -1) {
            Object.assign(recruitment.sessions[sIdx], session);
        }
    });
};

export const {
    // Recruitment
    useGetMyRecruitmentsQuery,
    useGetRecruitmentQuery,
    useLazyGetRecruitmentQuery,
    useAddRecruitmentMutation,
    useUpdateRecruitmentMutation,
    useDeleteRecruitmentMutation,
    // Session
    useGetRecruiterSessionQuery,
    useLazyGetRecruiterSessionQuery,
    useLazyGetCandidateSessionQuery,
    useAddSessionMutation,
    useUpdateSessionMutation,
    useDeleteSessionMutation,
    // Candidate / Recruiter Invitation
    useInviteCandidateMutation,
    useInviteRecruiterMutation,
    useInviteMemberMutation,
    useRemoveRecruiterMutation,
    useLazyGetRecruiterSessionParticipantsQuery,
    useLazyGetCandidateSessionParticipantsQuery,
    // Workspace
    useCreateWorkspaceMutation,
    useDeleteWorkspaceMutation,
    useChangeWorkspaceStatusMutation,
    // Snapshots
    useCreateSnapshotMutation,
    useDeleteSnapshotMutation,
    useGetRecruitmentSnapshotsQuery,
    // Files
    useUploadCVMutation
} = recruitmentApiService;
