import { Box, Link, styled, Tooltip, Typography, useTheme } from '@mui/material';
import NoFreeWorkspaceSlotsInfo from 'components/NoFreeWorkspaceSlotsInfo';
import NoSubscriptionBox from 'components/NoSubscriptionBox';
import { useAuth } from 'hooks/useAuth';
import { Recruitment, RecruitmentDTO, recruitmentFromDTO } from 'models/recruitment';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import {
    paymentService,
    useGetActiveSubscriptionQuery,
    useGetAvailableWorkspacesInfoQuery,
    useGetMyPaymentInfoQuery,
    useGetProductQuery
} from 'services/paymentService';
import { useGetMyRecruitmentsQuery } from 'services/recruitmentService';
import { arrayFromDTO } from 'store/dtoConverters';
import { isLightMode } from 'theme/ThemeProvider';
import HelpIcon from '@mui/icons-material/Help';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const BoxWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.alpha.trueWhite[50],
    backgroundColor: theme.colors.alpha[isLightMode(theme) ? 'white' : 'black'][10],
    height: 48,
    borderRadius: theme.general.borderRadiusLg
}));

const SubscriptionBox: FC = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const theme = useTheme();

    const { data: payment, isLoading: isLoadingPayment } = useGetMyPaymentInfoQuery();
    const { data: availableWorkspacesInfo, isLoading: isLoadingWorkspacesInfo } = useGetAvailableWorkspacesInfoQuery();
    const { data: subscription, isLoading: isLoadingSubscription } = useGetActiveSubscriptionQuery(payment?.subscription_id!, {
        skip: !paymentService.hasActiveSubscription(payment)
    });
    const { data: product, isLoading: isLoadingProduct } = useGetProductQuery(
        subscription?.items.data[0].price.product as string,
        {
            skip: !subscription
        }
    );
    const { createdWorkspacesCount, isLoading: isLoadingRecruitments } = useGetMyRecruitmentsQuery(undefined, {
        selectFromResult: ({ data, ...rest }) => {
            const recruitments = arrayFromDTO<RecruitmentDTO, Recruitment>(data, recruitmentFromDTO) ?? [];
            const count = paymentService.countCreatedWorkspaces(recruitments, user?.id!);

            return {
                createdWorkspacesCount: count,
                ...rest
            };
        }
    });

    if (isLoadingPayment || isLoadingWorkspacesInfo || isLoadingSubscription || isLoadingProduct || isLoadingRecruitments) {
        return (
            <BoxWrapper px={2}>
                <PulseLoader color={theme.colors.primary.main} />
            </BoxWrapper>
        );
    }

    const noFreeSlots =
        !availableWorkspacesInfo || (availableWorkspacesInfo && createdWorkspacesCount >= availableWorkspacesInfo.count);

    return (
        <BoxWrapper px={2} width={1}>
            {paymentService.hasActiveSubscription(payment) ? (
                <Box textAlign="center">
                    <Link
                        component={RouterLink}
                        to="/user/billing"
                        sx={{ color: theme.colors.alpha.trueWhite[100], fontWeight: 'bold' }}
                    >
                        {product?.name}
                    </Link>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography noWrap>
                            {t('Workspaces used')}: {createdWorkspacesCount} {t('of')} {availableWorkspacesInfo?.count}
                        </Typography>
                        {noFreeSlots && <NoFreeWorkspaceSlotsInfo darkBackground />}
                    </Box>
                </Box>
            ) : (
                <NoSubscriptionBox darkBackground />
            )}
        </BoxWrapper>
    );
};

export default SubscriptionBox;
