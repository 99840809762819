import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authApiService } from 'services/authService';
import { broadcastApiService } from 'services/broadcastService';
import { fileApiService } from 'services/fileService';
import { paymentApiService } from 'services/paymentService';
import { recruitmentApiService } from 'services/recruitmentService';
import { userApiService } from 'services/userService';
import { workspaceApiService } from 'services/workspaceService';
import listenerMiddleware from 'store/listenerMiddleware';
import authReducer, { clearCredentials } from 'store/slices/authSlice';
import { snapshotApiService } from 'services/snapshotService';
import { aiApiService } from 'services/aiService';

const combinedReducer = combineReducers({
    [recruitmentApiService.reducerPath]: recruitmentApiService.reducer,
    [workspaceApiService.reducerPath]: workspaceApiService.reducer,
    [snapshotApiService.reducerPath]: snapshotApiService.reducer,
    [broadcastApiService.reducerPath]: broadcastApiService.reducer,
    [userApiService.reducerPath]: userApiService.reducer,
    [fileApiService.reducerPath]: fileApiService.reducer,
    [authApiService.reducerPath]: authApiService.reducer,
    [paymentApiService.reducerPath]: paymentApiService.reducer,
    [aiApiService.reducerPath]: aiApiService.reducer,
    auth: authReducer
});

const reducer: typeof combinedReducer = (state, action) => {
    if (clearCredentials.match(action)) {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend(listenerMiddleware.middleware)
            .concat(
                recruitmentApiService.middleware,
                workspaceApiService.middleware,
                snapshotApiService.middleware,
                broadcastApiService.middleware,
                fileApiService.middleware,
                userApiService.middleware,
                authApiService.middleware,
                paymentApiService.middleware,
                aiApiService.middleware
            )
});

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;
