import dayjs, { Dayjs } from 'dayjs';
import { Workspace } from 'models/workspace';
import { User, UserDTO, userFromDTO } from './user';

interface CandidateBase {
    id: string;
    first_name: string;
    last_name: string;
    cv_file_path: string;
    email: string;
}

interface CandidateDTO extends CandidateBase {
    created_time: string;
    updated_time: string;
    sessions: SessionDTO[] | null;
}

export interface Candidate extends CandidateBase {
    name: string;
    created_time: Dayjs;
    updated_time: Dayjs;
    sessions: Session[] | null;
}

interface InvitedRecruiter {
    email: string;
    recruitment_id: string;
    session_id: string;
    tenant: string;
}

interface SessionBase {
    id: string;
    name: string;
    invited_recruiters: InvitedRecruiter[];
}

export interface SessionDTO extends SessionBase {
    created_time: string;
    updated_time: string;
    start_time: string;
    end_time: string;
    owner: UserDTO;
    candidate: CandidateDTO | null;
    recruiters: UserDTO[];
    workspace: Workspace;
}

export interface Session extends SessionBase {
    created_time: Dayjs;
    updated_time: Dayjs;
    start_time: Dayjs;
    end_time: Dayjs;
    owner: User;
    candidate: Candidate | null;
    description?: string;
    recruiters: User[];
    workspace: Workspace;
}

export interface SessionPayload {
    name?: string;
    description?: string;
    recruitmentId: string;
    start_time: string;
    end_time: string;
}

export interface SessionUpdatePayload extends SessionPayload {
    id: string;
}

export interface SessionDeletePayload {
    recruitmentId: string;
    sessionId: string;
}

interface RecruitmentBase {
    id: string;
    name: string;
    description: string;
}

export interface RecruitmentDTO extends RecruitmentBase {
    created_time: string;
    updated_time: string;
    candidate: CandidateDTO | null;
    owner: UserDTO;
    sessions: SessionDTO[];
}

export interface Recruitment extends RecruitmentBase {
    created_time: Dayjs;
    updated_time: Dayjs;
    candidate: Candidate | null;
    owner: User;
    sessions: Session[];
}

export interface Invitation {
    recruitmentId: string;
    sessionId: string;
}

export interface CandidateInvitation extends Invitation {
    candidate: InvitationCandidate;
}

export interface RecruiterInvitation extends Invitation {
    recruiter: InvitationRecruiter;
}

export interface RecruiterRemoval extends Invitation {
    recruiterId: string;
}

export interface InvitationCandidate {
    first_name: string;
    last_name: string;
    email: string;
}

export interface InvitationRecruiter {
    email: string;
}

export interface SessionParticipants {
    candidate: SessionParticipant;
    recruiters: SessionParticipant[];
}

export interface SessionParticipant {
    id: string;
    first_name: string;
    last_name: string;
    session: Session;
    status?: SessionParticipantStatus;
    isCandidate?: boolean;
}

export interface SessionParticipantStatus {
    userId?: string;
    connected?: boolean;
    speaking?: boolean;
    muted?: boolean;
    privacy?: boolean;
}

export const candidateFromDTO = (dto: CandidateDTO): Candidate => ({
    ...dto,
    name: `${dto.first_name} ${dto.last_name}`,
    created_time: dayjs(dto.created_time),
    updated_time: dayjs(dto.updated_time),
    sessions: dto.sessions?.length ? dto.sessions.map(sessionFromDTO) : []
});

export const sessionFromDTO = (dto: SessionDTO): Session => ({
    ...dto,
    created_time: dayjs(dto.created_time),
    updated_time: dayjs(dto.updated_time),
    start_time: dayjs(dto.start_time),
    end_time: dayjs(dto.end_time),
    owner: userFromDTO(dto.owner),
    candidate: dto.candidate ? candidateFromDTO(dto.candidate) : null,
    recruiters: dto.recruiters.length ? dto.recruiters.map(userFromDTO) : []
});

export const recruitmentFromDTO = (dto: RecruitmentDTO): Recruitment => ({
    ...dto,
    created_time: dayjs(dto.created_time),
    owner: userFromDTO(dto.owner),
    candidate: dto.candidate ? candidateFromDTO(dto.candidate) : null,
    updated_time: dayjs(dto.updated_time),
    sessions: dto.sessions.map(sessionFromDTO)
});

export const getNextSession = (sessions: Session[]): Session | null =>
    sessions.sort((a, b) => a.end_time.diff(b.end_time)).find((session) => session.end_time.isAfter(dayjs())) || null;
