export enum GACategory {
    Workspace = 'workspace',
    Recruitment = 'recruitment',
    Team = 'team',
    Profile = 'profile',
    Billing = 'billing'
}

export enum GAAction {
    Add = 'add',
    Update = 'update',
    Delete = 'delete',
    Join = 'join',
    GuestJoin = 'guest_join',
    AddMember = 'add_member',
    RemoveMember = 'remove_member',
    SetQuality = 'set_quality',
    SetCompression = 'set_compression',
    ChangePrivacy = 'change_privacy',
    SwitchParticipant = 'switch_participant',
    UploadFile = 'upload_file',
    ToggleTheme = 'toggle_theme',
    SwitchLanguage = 'switch_language',
    UpdateCredentials = 'update_credentials',
    ChangePassword = 'change_password',
    Subscribe = 'subscribe',
    ManageSubscription = 'manage_subscription',
    CreateSnapshot = 'create_snapshot',
    UpdateSnapshot = 'update_snapshot',
    DeleteSnapshot = 'delete_snapshot'
}
