import { createContext, FC, PropsWithChildren, useState } from 'react';

import { Breakpoint, Dialog, DialogProps } from '@mui/material';

type DialogContextState = {
    openDialog: (content: JSX.Element, props?: Partial<DialogProps>) => void;
    closeDialog: () => void;
    setMaxWidth: (width: false | Breakpoint | undefined) => void;
};

export const DialogContext = createContext<DialogContextState>({} as DialogContextState);

export const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
    const [content, setContent] = useState<JSX.Element | null>(null);
    const [props, setProps] = useState<Partial<DialogProps> | null>(null);
    const [maxWidth, setMaxWidth] = useState<false | Breakpoint | undefined>(props?.maxWidth);

    const openDialog = (content: JSX.Element, props?: Partial<DialogProps>) => {
        setContent(content);
        setProps(props || null);
    };

    const closeDialog = () => {
        setContent(null);
        setMaxWidth(undefined);
    };

    return (
        <DialogContext.Provider value={{ openDialog, closeDialog, setMaxWidth }}>
            {children}
            <Dialog {...props} open={!!content} onClose={closeDialog} maxWidth={maxWidth}>
                {content}
            </Dialog>
        </DialogContext.Provider>
    );
};
