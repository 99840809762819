import { createApi } from '@reduxjs/toolkit/query/react';
import { WorkspaceConnectionDetails, WorkspaceId } from 'models/workspace';
import axiosBaseQuery from 'store/axiosBaseQuery';

const workspaceService = {
    getWorkspaceUrl: (workspaceId: WorkspaceId): string => {
        return `/workspace/${workspaceId.recruitmentId}/${workspaceId.sessionId}`;
    },

    getCandidateWorkspaceUrl: (workspaceId: WorkspaceId): string => {
        return `/workspace/candidate/${workspaceId.recruitmentId}/${workspaceId.sessionId}/${workspaceId.code}`;
    },

    openWorkspaceInNewTab: (workspaceId: WorkspaceId): void => {
        const win = window.open(workspaceService.getWorkspaceUrl(workspaceId), '_blank');
        if (win != null) {
            win.focus();
        }
    },

    getWorkspaceStreamUrl: (url: string, sessionId: string) => {
        return `wss://${url}/api/v1/vms/${sessionId}/graphics/stream`;
    }
};

export default workspaceService;

// ////

enum TagType {
    Workspaces = 'workspaces'
}

export const workspaceApiService = createApi({
    reducerPath: 'workspaceApiService',
    baseQuery: axiosBaseQuery({ basePath: '/recruitments' }),
    tagTypes: [TagType.Workspaces],
    endpoints: (builder) => ({
        // getWorkspace: builder.query<Workspace, string>({
        //     query: (workspaceId) => ({ basePathOverride: '/vms', url: `/one/${workspaceId}`, method: 'get' }),
        //     providesTags: (result, error, id) => [{ type: TagType.Workspaces, id }]
        // }),

        getWorkspaceConnectionDetails: builder.query<WorkspaceConnectionDetails, WorkspaceId>({
            query: ({ recruitmentId, sessionId }) => ({
                url: `/one/${recruitmentId}/sessions/one/${sessionId}/workspace/console/presigned/unlimited`,
                method: 'put'
            }),
            transformResponse: (response: WorkspaceConnectionDetails, meta, { sessionId }) => {
                return {
                    ...response,
                    sessionId
                };
            }
        }),
        getCandidateWorkspaceConnectionDetails: builder.query<WorkspaceConnectionDetails, WorkspaceId>({
            query: ({ recruitmentId, sessionId, code }) => ({
                basePathOverride: '/tenants',
                url: `/one/cmit/recruitments/one/${recruitmentId}/sessions/one/${sessionId}/vm/console/presigned/limited`,
                method: 'put',
                params: { code }
            }),
            transformResponse: (response: WorkspaceConnectionDetails, meta, { sessionId }) => {
                return {
                    ...response,
                    sessionId
                };
            }
        }),
        uploadRecruiterFileToWorkspace: builder.mutation<
            void,
            { id: WorkspaceId; file: File; onUploadProgress: (progressEvent: ProgressEvent) => void }
        >({
            query: ({ id, file, onUploadProgress }) => ({
                method: 'put',
                url: `/one/${id.recruitmentId}/sessions/one/${id.sessionId}/workspace/files/stream`,
                headers: { 'Content-Type': file.type },
                params: {
                    path: `/home/teamsharq/Desktop/${file.name}`
                },
                data: file,
                onUploadProgress
            })
        })
    })
});

export const {
    // useGetWorkspaceQuery,
    // useGetWorkspaceConnectionDetailsQuery,
    useLazyGetWorkspaceConnectionDetailsQuery,
    useLazyGetCandidateWorkspaceConnectionDetailsQuery,
    useUploadRecruiterFileToWorkspaceMutation
} = workspaceApiService;
