import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Environment } from 'environment';
import { User } from 'models/user';

export type AppConfig = {
    recruitmentMode?: boolean;
    courseMode?: boolean;
    workMode?: boolean;
    isaMode?: boolean;
    item: string;
    items: string;
    itemCased: string;
    itemsCased: string;
    member: string;
    memberCased: string;
    managerCased: string;
    candidate: string;
    candidates: string;
    title: string;
    titleCased: string;
};

type AuthState = {
    initialized: boolean;
    user: User | null;
    token: string | null;
    appConfig: AppConfig;
};

const recruitmentMode = Environment.appMode === 'recruitments';
const courseMode = Environment.appMode === 'courses';

const initialState: AuthState = {
    initialized: false,
    user: null,
    token: null,
    appConfig: {
        recruitmentMode: recruitmentMode,
        courseMode: courseMode,
        workMode: courseMode && window.location.hostname.match(/app.*-work/)?.length === 1,
        isaMode: courseMode && window.location.hostname.match(/app.*-isa/)?.length === 1,
        item: courseMode ? 'team' : 'recruitment',
        items: courseMode ? 'teams' : 'recruitments',
        itemCased: courseMode ? 'Team' : 'Recruitment',
        itemsCased: courseMode ? 'Teams' : 'Recruitments',
        member: courseMode ? 'team member' : 'session',
        memberCased: courseMode ? 'Team member' : 'Session',
        managerCased: courseMode ? 'Manager' : 'Coordinator',
        candidate: courseMode ? 'member' : 'candidate',
        candidates: courseMode ? 'members' : 'candidates',
        title: courseMode ? 'name' : 'title',
        titleCased: courseMode ? 'Name' : 'Title'
    }
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initAuth: () => initialState,
        setCredentials: (state, { payload }: PayloadAction<Partial<AuthState>>) => ({
            ...state,
            ...payload,
            initialized: true
        }),
        clearCredentials: () => ({ ...initialState, initialized: true })
    }
});

export const { initAuth, setCredentials, clearCredentials } = slice.actions;

export default slice.reducer;
