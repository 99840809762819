import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/axiosBaseQuery';

const fileService = {
    downloadFile: async (link: string, filename: string) => {
        if (filename.endsWith('.pdf')) {
            window.open(link, '_blank')?.focus();
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = link;
            a.download = filename;
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
            }, 0);
        }
    }
};

export default fileService;

enum TagType {
    Files = 'files'
}

export const fileApiService = createApi({
    reducerPath: 'fileApiService',
    baseQuery: axiosBaseQuery(),
    tagTypes: [TagType.Files],
    endpoints: (builder) => ({
        getFile: builder.query<string, { url: string; fileName: string }>({
            query: ({ url, fileName }) => ({
                url,
                method: 'get',
                params: {
                    path: fileName
                },
                responseType: 'blob'
            }),
            // TODO: handle revokeObjectURL
            // If image has no size result will be empty string instead of url without proper image
            transformResponse: (result: Blob, _, { fileName }) => {
                if (result.size) {
                    if (fileName.endsWith('.pdf')) {
                        const newBlob = new Blob([result], { type: 'application/pdf' });
                        return URL.createObjectURL(newBlob);
                    }
                    return URL.createObjectURL(result);
                }
                return '';
            },
            providesTags: (result, error, { url }) => [{ type: TagType.Files, id: url }]
        }),
        getImageFile: builder.query<string, string>({
            query: (url) => ({ url, method: 'get', responseType: 'blob', headers: { 'Content-Type': 'image/*' } }),
            // TODO: handle revokeObjectURL
            // If image has no size result will be empty string instead of url without proper image
            transformResponse: (result: Blob) => (result.size ? URL.createObjectURL(result) : ''),
            providesTags: (result, error, url) => [{ type: TagType.Files, id: url }]
        }),
        uploadFile: builder.mutation<void, { url: string; file: File; addFilename?: boolean }>({
            query: ({ url, file, addFilename }) => ({
                url,
                params: addFilename
                    ? {
                          path: file.name
                      }
                    : null,
                method: 'put',
                headers: { 'Content-Type': file.type },
                data: file
            }),
            invalidatesTags: (result, error, { url }) => [{ type: TagType.Files, id: url }]
        })
    })
});

export const { useGetImageFileQuery, useGetFileQuery, useLazyGetFileQuery, useLazyGetImageFileQuery, useUploadFileMutation } =
    fileApiService;
