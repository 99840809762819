import packageJson from '../package.json';

export class Environment {
    static appVersion: string = packageJson.version;

    static appMode = process.env.REACT_APP_MODE!;

    static apiUrl: string = process.env.REACT_APP_API_URL!;

    static workspaceRegion: string = process.env.REACT_APP_WORKSPACE_REGION!;

    static workspaceTags: string = process.env.REACT_APP_WORKSPACE_TAGS!;

    static recaptchaKey: string = process.env.REACT_APP_RECAPTCHA_KEY!;

    static gaMeasurementId: string = process.env.REACT_APP_GA_MEASUREMENT_ID!;

    private static hash: string = btoa(process.env.REACT_APP_API_URL!);

    static themeKey: string = `CMIT_THEME_KEY_${Environment.hash}`;

    static accessTokenKey: string = `CMIT_ACCESS_TOKEN_${Environment.hash}`;

    static recruitmentsViewModeKey: string = `CMIT_RECRUITMENTS_VIEW_MODE_${Environment.hash}`;

    static refreshTokenKey: string = `CMIT_REFRESH_TOKEN_${Environment.hash}`;

    static rememberMeKey: string = `CMIT_REMEMBER_ME_${Environment.hash}`;

    static currentUserAvatarKey: string = `CMIT_ME_AVATAR_${Environment.hash}`;
}
