import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/axiosBaseQuery';
import { SnapshotDTO } from 'models/snapshot';

const snapshotService = {};

export default snapshotService;

// ////

enum TagType {
    BasicSnapshots = 'snapshots'
}

enum TagId {
    List = 'list'
}

export const snapshotApiService = createApi({
    reducerPath: 'snapshotApiService',
    baseQuery: axiosBaseQuery({ basePath: '/snapshots' }),
    tagTypes: [TagType.BasicSnapshots],
    endpoints: (builder) => ({
        getBasicSnapshots: builder.query<SnapshotDTO[], void>({
            query: () => ({
                method: 'get'
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: TagType.BasicSnapshots, id } as const)),
                          { type: TagType.BasicSnapshots, id: TagId.List }
                      ]
                    : [{ type: TagType.BasicSnapshots, id: TagId.List }]
        })
    })
});

export const { useGetBasicSnapshotsQuery } = snapshotApiService;
