import {alpha, Box, Card, Container, darken, Divider, styled} from '@mui/material';
import {useAuth} from 'hooks/useAuth';
import NavigationMenu from 'layouts/MainLayout/TopBar/NavigationMenu';
import SubscriptionBox from 'layouts/MainLayout/TopBar/SubscriptionBox';

import Logo from './Logo';
import UserBox from './UserBox';

const TopBarWrapper = styled(Card)(({theme}) => ({
    color: theme.header.textColor,
    background: alpha(darken(theme.colors.primary.dark, 0.2), 0.95),
    backdropFilter: 'blur(5px)',
    margin: theme.spacing(0, 0, 5),
    padding: theme.spacing(4, 0, 44),
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 40,
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 8, 5),
        padding: theme.spacing(4, 3, 44)
    }
}));

const TopBarImage = styled(Box)({
    backgroundSize: 'cover',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.7
});

const DividerWrapper = styled(Divider)(({theme}) => ({
    background: theme.colors.alpha.trueWhite[10]
}));

function TopBar() {
    const {appConfig} = useAuth();

    let headerBg = '/static/images/header-background.png';

    return (
        <TopBarWrapper>
            <TopBarImage
                sx={{
                    opacity: 0.85,
                    backgroundImage: `url("${headerBg}")`
                }}
            />
            <Container
                sx={{
                    zIndex: 6
                }}
                maxWidth="xl"
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex">
                        <Logo/>
                    </Box>
                    <Box display="flex" gap={1}>
                        {appConfig.recruitmentMode && <SubscriptionBox/>}
                        <UserBox/>
                    </Box>
                </Box>
                <DividerWrapper
                    sx={{
                        display: {xs: 'none', md: 'flex'},
                        mt: 4,
                        mb: 1
                    }}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        display: {xs: 'none', md: 'inline-block'}
                    }}
                >
                    <NavigationMenu/>
                </Box>
            </Container>
        </TopBarWrapper>
    );
}

export default TopBar;
