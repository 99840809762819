import { FC } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { useGetRecruitmentQuery } from 'services/recruitmentService';
import { objectFromDTO } from 'store/dtoConverters';
import { recruitmentFromDTO } from 'models/recruitment';

interface Props {
    onlyRecruitmentOwner?: boolean;
    redirectPath: string;
    children: JSX.Element;
}

const Authorized: FC<Props> = ({ redirectPath = '/', children, onlyRecruitmentOwner }) => {
    const { user, appConfig } = useAuth();
    const redirect = <Navigate to={redirectPath} replace />;
    const location = useLocation();

    const { recruitmentId } = useParams();
    const { recruitment } = useGetRecruitmentQuery(recruitmentId!, {
        selectFromResult: ({ data, ...rest }) => ({
            recruitment: objectFromDTO(data, recruitmentFromDTO),
            ...rest
        }),
        skip: !recruitmentId
    });

    if (!user) {
        return redirect;
    }

    if (onlyRecruitmentOwner && recruitment)
        if (location.pathname.includes(`${appConfig.items}/single`) && user.id !== recruitment.owner.id)
            return <Navigate to={`/${appConfig.items}/single/${recruitmentId}`} replace />;

    return children;
};

export default Authorized;
